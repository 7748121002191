import styled from "styled-components";

const HomeContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
`;

const PageTitle = styled.div``;

const ArtworksWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  max-width: 1200px;
  margin: auto;
`;

const SingleArtwork = styled.div`
  // max-width: 320px;
  flex: 1 0 50%;
  padding: 30px 0;
`;

const SingleArtworkTitle = styled.p`
  font-size: 16px;
  font-style: italic;
  margin: 0;
`;

const SingleArtworkAuthor = styled.p`
  font-size: 16px;
  margin: 2px 0;
`;

export {
  HomeContainer,
  PageTitle,
  ArtworksWrapper,
  SingleArtwork,
  SingleArtworkTitle,
  SingleArtworkAuthor,
};
