import styled from "styled-components";

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
`;

const ArtworkContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SingleArtwork = styled.div`
  // max-width: 320px;
  flex-grow: 1;
`;

const ArtworkDataWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const ArtworkTitleAndYearWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0;
  padding-top: 10px;
  gap: 3px;
`;
export {
  LoaderWrapper,
  ArtworkContainer,
  SingleArtwork,
  ArtworkDataWrapper,
  ArtworkTitleAndYearWrapper,
};
